import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ITreeOption } from '@e-bilet/ui-tree-select';
import { Province } from '../rest-api/models/province.model';
import { SearchRequest } from '../rest-api/models/search-request.model';
import { ISearchPageQueryParams } from '../search-page/search-page-query-params.interface';
import { LocalizationTreeStoreService } from '../stores/localization-tree-store.service';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    private readonly _localizationTreeStoreService$ = inject(LocalizationTreeStoreService).get();

    private _locationTree: ITreeOption<Province>[] = [];

    get locationTree(): ITreeOption<Province>[] {
        return this._locationTree;
    }

    constructor() {
        this._localizationTreeStoreService$
            .pipe(takeUntilDestroyed())
            .subscribe((locationTree: ITreeOption<Province>[]) => (this._locationTree = locationTree));
    }

    public mapToSearchPageQueryParams(
        lastRequest: SearchRequest,
        isQuickSearchInputGroup: boolean,
    ): ISearchPageQueryParams | null {
        if (isQuickSearchInputGroup) {
            return this._mapToSearchPageQueryParamsQuickSearchInputGroup(lastRequest);
        } else {
            return this._mapToSearchPageQueryParamsSearchSelect(lastRequest);
        }
    }

    private _prepareTextToSearch(text: string): string {
        // najpierw zamieniamy wyszukiwaną fraze na małe litery,
        // potem używamy .normalize('NFD') co spowoduje rozkład połączonych znaków na znaki podstawowe i oddzielne znaki diakrytyczne,
        // na koniec zamieniamy znaki diakrytyczne na pusty string i zamieniamy wszystkie wystąpienia litery "ł" na "l"
        return text
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\u0142/g, 'l');
    }

    private _textToCity(text: string): string | undefined {
        return this.locationTree
            .flatMap(({ value: { cities } }) => cities)
            .find(({ slug }) => slug === this._prepareTextToSearch(text))?.slug;
    }

    private _getProvinceOfCity(text: string): ITreeOption<Province> | undefined {
        return this.locationTree.find(({ value: { cities } }) =>
            cities.some(({ slug }) => slug === this._prepareTextToSearch(text)),
        );
    }

    private _handleCityNameSearch(lastRequest: SearchRequest): ISearchPageQueryParams {
        const province = this._getProvinceOfCity(lastRequest.text);
        const city = this._textToCity(lastRequest.text);
        return {
            province: province?.value.slug ?? lastRequest.provinceSlug,
            city: city ?? lastRequest.citySlug,
        };
    }

    private _checkIfSearchTextIsCity(text: string): boolean {
        return this.locationTree
            .flatMap(({ value: { cities } }) => cities)
            .some(({ slug }) => slug === this._prepareTextToSearch(text));
    }

    private _mapToSearchPageQueryParamsQuickSearchInputGroup(
        lastRequest: SearchRequest,
    ): ISearchPageQueryParams | null {
        if (lastRequest) {
            const textIsCity =
                lastRequest.text && !lastRequest.province && !lastRequest.city
                    ? this._checkIfSearchTextIsCity(lastRequest.text)
                    : null;

            const queryParams: ISearchPageQueryParams = textIsCity
                ? this._handleCityNameSearch(lastRequest)
                : {
                      text: lastRequest.text || undefined,
                      province: lastRequest.provinceSlug,
                      city: lastRequest.citySlug,
                  };

            if (lastRequest.date) {
                queryParams.date = lastRequest.date;
            } else if (lastRequest.dateFrom && lastRequest.dateTo) {
                [queryParams.dateFrom, queryParams.dateTo] = [lastRequest.dateFrom, lastRequest.dateTo];
            }

            return queryParams;
        } else {
            return null;
        }
    }

    private _mapToSearchPageQueryParamsSearchSelect(lastRequest: SearchRequest): ISearchPageQueryParams | null {
        if (lastRequest) {
            const textIsCity =
                this._checkIfSearchTextIsCity(lastRequest.text) && !lastRequest.provinceSlug && !lastRequest.citySlug;

            const queryParams: ISearchPageQueryParams = {
                text: textIsCity ? '' : lastRequest.text || undefined,
                dateFrom: lastRequest.dateFrom,
                dateTo: lastRequest.dateTo,
                province: textIsCity ? this._getProvinceOfCity(lastRequest.text)?.value.slug : lastRequest.provinceSlug,
                city: textIsCity ? this._textToCity(lastRequest.text) : lastRequest.citySlug,
            };

            return queryParams;
        } else {
            return null;
        }
    }
}
