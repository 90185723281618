import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HomeRestService } from '../rest-api/home-rest.service';
import { Title } from '../rest-api/models/title.model';
import { BaseStoreService } from './base-store.service';

@Injectable({
    providedIn: 'root',
})
export class OnTimeStoreService extends BaseStoreService<null, Title[]> {
    private readonly _homeRestService = inject(HomeRestService);

    protected _shouldLoad(newRequest: null): boolean {
        return !this._loadingStream$.value && !this._lastResponse;
    }

    protected _getRest(): (request: null) => Observable<Title[]> {
        return () => this._homeRestService.getOnTime();
    }

    public load(): Observable<Title[]> {
        return super.load(null);
    }

    public get(): Observable<Title[]> {
        this.load();
        return super.get();
    }
}
