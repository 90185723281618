<form [formGroup]="searchForm" class="eb-input-group quick-search-input-group" #formEl>
  <eb-search-select
    class="eb-search-select--quick-search"
    [searchParams]="searchForm.value"
    [searchText]="searchText()"
    [isQuickSearchInputGroup]="true"
    [showIcon]="false"
    [showSuffixIcon]="false"
    size="large"
    [theme]="theme()"
    [overlayOrigin]="overlayOrigin?.elementRef"
    [debounceTime]="300"
    [autoFocus]="autoFocus()"
    (selectedChange)="selectedChange.emit($event)"
    (click)="onInputClick(EbQuickSearchInputGroupEnum.SEARCH_SELECT)"
  />
  <eb-date-range-picker
    class="eb-date-range-picker--quick-search"
    [label]="datePlaceholder()"
    formControlName="dateRange"
    [onlyIcon]="isCompact()"
    size="large"
    [theme]="theme()"
    [origin]="isCompact() ? overlayOrigin : undefined"
    (click)="onInputClick(EbQuickSearchInputGroupEnum.DATE_RANGE_PICKER)"
  />
  <eb-location-tree-select
    class="eb-location-tree-select--quick-search"
    formControlName="location"
    [onlyIcon]="isCompact()"
    [options]="locationTree"
    size="large"
    [theme]="theme()"
    [origin]="isCompact() ? overlayOrigin : undefined"
    [formElement]="formEl"
    (click)="onInputClick(EbQuickSearchInputGroupEnum.LOCATION_TREE_SELECT)"
  />
  <button
    eb-button
    [ebType]="showPrimarySearchButton() ? 'primary' : 'secondary'"
    ebSize="large"

    type="submit"
    (click)="submit()"
    class="eb-search-select--search-button eb-lg-visible"
    *transloco="let t"
  >
    <eb-icon key="search" ebSize="large" class="mr-2" /> {{ t('shared.search-input.search') }}
  </button>
</form>
