<eb-select
  [icon]="showIcon() ? 'search' : undefined"
  [label]="searchText()"
  [size]="size()"
  [theme]="theme()"
  [overlayOrigin]="overlayOrigin()"
  [overlayWidth]="overlayOrigin()?.nativeElement.offsetWidth"
  [loading]="loading()"
  [serverFilter]="true"
  [customValue]="true"
  [showSuffixIcon]="showSuffixIcon()"
  (searching)="searching.emit($event)"
  (clearInput)="clear()"
  [placeholder]="searchSelectPlaceholderText()"
>
  <div class="p-3">
    @if (options().length) {
      <!-- search result -->
      @for (group of options(); track null; let idx = $index) {
        <ng-container>
          <ng-container
            *ngTemplateOutlet="
              optionGroupTmpl;
              context: { $implicit: group.items, title: group.label, index: idx, total: group.total, itemTmpl: getTemplate(group.type) }
            "
          />
        </ng-container>
      }
    } @else {
      <!-- proposed result -->
      <p *transloco="let t" class="option-group__name mb-3">{{ t('shared.search-select.featuredEvents') }}</p>
      @for (item of proposedResult()?.titleSearch; track item.id; let i = $index) {
        <eb-select-option [focused]="i === selectOptionFocusCounter()" [option]="{ label: '', value: item, translationKey: '' }">
          <div>
            <ng-container *ngTemplateOutlet="titleOptionTemplate; context: { $implicit: item, i: i, isProposedResult: true }" />
          </div>
        </eb-select-option>
      }
      @if ((proposedResult()?.titleSearch?.length || 0) >= MIN_NUMBER_OF_RESULTS_TO_DISPLAY_SHOW_ALL) {
        <button eb-button ebType="secondary" ebSize="small" (click)="selectGroup()">
          {{ 'shared.search-select.showAll' | transloco }}
        </button>
      }
    }
  </div>

  <ng-template #optionGroupTmpl let-title="title" let-total="total" let-itemTmpl="itemTmpl" let-items let-idx="index">
    <p class="option-group__name mb-3" *transloco="let t">{{ t(title) }} ({{ total || 0 }})</p>
    @for (item of items; track i; let i = $index) {
      <eb-select-option [option]="item">
        <div>
          <ng-container
            *ngTemplateOutlet="
              itemTmpl;
              context: {
                $implicit: item,
                i: i,
                minPrice: titleEventAvailabilityDataMap().get(item.id)?.minPrice,
                isPromoted: titleEventAvailabilityDataMap().get(item.id)?.isPromoted,
                isAvailable: titleEventAvailabilityDataMap().get(item.id)?.titleEventAvailability === titleEventAvailabilities.AVAILABLE,
              }
            "
          />
        </div>
      </eb-select-option>
    }
    @if (total > 3) {
      <button eb-button ebType="secondary" ebSize="small" (click)="selectGroup(idx)" *transloco="let t">
        {{ t('shared.search-select.showAll') }}
      </button>
    }

    <div class="eb-divider option-group__divider"></div>
  </ng-template>

  <ng-template #titleOptionTemplate let-title let-i="i" let-isProposedResult="isProposedResult">
    <div
      ebIntersection
      [ebOnlyFirst]="true"
      (intersecting)="aggregatedGa4EventsService.titleSearchItemIntersecting(title, isProposedResult, i)"
      class="search-option"
      (click)="selectTitleOption($event, title, i)"
    >
      <div class="search-option__thumbnail">
        <img [src]="title.imageLandscape | media" [alt]="title.title" />
      </div>
      <div class="ml-3">
        <span class="search-option__title">{{ title.title }}</span>
        <span class="search-option__description eb-size--small line-height-3">
          @if (title.isPromotion) {
            <eb-badge type="promotion" [size]="'small'" />
            <span class="eb-text-divider"></span>
          }
          @if (title.dateFrom && title.dateDisplayMode !== DateDisplayMode.HIDE) {
            <span class="white-space-nowrap">{{ title.dateFrom | ebDate: title.dateTo }}</span>
            <span class="eb-text-divider"></span>
          }
          @if (title.place) {
            <span>{{ title.place }}</span>
          }
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template #eventOptionTemplate let-event let-i="i" let-minPrice="minPrice" let-isPromoted="isPromoted" let-isAvailable="isAvailable">
    <div
      ebIntersection
      [ebOnlyFirst]="true"
      (intersecting)="aggregatedGa4EventsService.eventSearchItemIntersecting(event)"
      class="search-option"
      (click)="handleSearchClick($event, event, i, event)"
    >
      <div class="search-option__thumbnail">
        <img [src]="event.imageLandscape | media" [alt]="event.title" />
      </div>
      <div class="ml-3" ebIntersection [ebOnlyFirst]="true" (intersecting)="checkAvailability(event)">
        <span class="search-option__title">{{ event.title }}</span>
        <span class="search-option__description eb-size--small">
          @if (!isPromoted && isAvailable && (minPrice | ebIsNumber)) {
            <span *transloco="let t">{{ t('shared.search-select.from') }} {{ minPrice | ebCurrency: 'PLN' }}</span>
          }
          @if (isPromoted) {
            <eb-badge type="promotion" [size]="'small'" />
          }
          @if (((minPrice | ebIsNumber) && isAvailable) || isPromoted) {
            <span class="eb-text-divider"></span>
          }
          @if (event.dateDisplayMode !== DateDisplayMode.HIDE) {
            <span>{{ event.dateEvent | ebDate }}</span>
          }
          @if (event.address.city && event.dateDisplayMode !== DateDisplayMode.HIDE) {
            <span class="eb-text-divider"></span>
            <span>{{ event.address.city }}, {{ event.address.namePlace }}, {{ event.address.street }}</span>
          }
        </span>
      </div>
      <div *transloco="let t" class="search-option__action">
        @switch (titleEventAvailabilityDataMap().get(event.id)?.titleEventAvailability) {
          @case (titleEventAvailabilities.AVAILABLE) {
            <eb-buy-ticket-button [titleEvent]="event" [ebSize]="'small'" [virtualPageView]="virtualPageView">
              <eb-icon key="ticket" /> {{ t('shared.search-select.buyTicket') }}
              <ng-container eb-buy-ticket-queue>
                <p class="eb-size--tiny search-queue">
                  <span [innerHtml]="t('libs.title-event-bar.redirectToShop')"></span>
                </p>
              </ng-container>
            </eb-buy-ticket-button>
          }
          @case (titleEventAvailabilities.CURRENTLY_UNAVAILABLE) {
            <div class="flex flex-column align-items-center mr-3">
              <p class="eb-size--tiny mb-0">{{ t('shared.search-select.ticketsTemporarilyUnavailable') }}</p>
              <p class="eb-size--tiny">{{ t('shared.search-select.firstToKnow') }}</p>
            </div>

            <button eb-button ebType="primary" ebType="secondary" ebSize="small">
              <eb-icon key="bell" size="md" /> {{ t('shared.search-select.notifyMe') }}
            </button>
          }
          @case (titleEventAvailabilities.SOLD_OUT) {
            <p class="h6 eb-title-event-bar__sold-out">{{ t('shared.search-select.soldOut') }}</p>
          }
          @case (titleEventAvailabilities.SELL_OPEN_SOON) {
            <p class="h6">{{ t('shared.search-select.saleSoon') }}</p>
          }
          @case (titleEventAvailabilities.SELL_ENDED) {
            <p class="h6">{{ t('shared.search-select.saleEnded') }}</p>
          }
          @case (titleEventAvailabilities.CHECK_FREE_SEATS) {
            <eb-icon key="loading" />
          }
        }
      </div>
    </div>
  </ng-template>

  <ng-template #artistsOptionTemplate let-artist>
    <div class="search-option" (click)="selectArtistOption($event, artist)">
      <div class="search-option__thumbnail">
        <div class="img--circle">
          <img [src]="artist.imageUrl | media" [alt]="artist.artistName" />
        </div>
      </div>
      <div class="ml-3">
        <span class="search-option__title">{{ artist.artistName }}</span>
      </div>
    </div>
  </ng-template>

  <ng-template #placeOptionTemplate let-place>
    <div class="search-option" (click)="selectPlaceOption($event, place)">
      <div class="search-option__thumbnail">
        <img [src]="place.imageUrl | media" [alt]="place.customName" />
      </div>
      <div class="ml-3">
        <span class="search-option__title">{{ place.customName }}</span>
        <span class="search-option__description eb-size--small">
          <span>{{ place.city }}</span>
        </span>
      </div>
    </div>
  </ng-template>
</eb-select>
