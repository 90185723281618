import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Article } from '../rest-api/models/article.interface';
import { CreativeContentRestService } from '../rest-api/creative-content-rest.service';
import { BaseStoreService } from './base-store.service';

@Injectable({
    providedIn: 'root',
})
export class CreativeContentStoreService extends BaseStoreService<null, Article[]> {
    private readonly _creativeContentRestService = inject(CreativeContentRestService);

    protected _shouldLoad(newRequest: null): boolean {
        return !this._loadingStream$.value && !this._lastResponse;
    }

    protected _getRest(): (request: null) => Observable<Article[]> {
        return () => this._creativeContentRestService.getArticlesForHomePage();
    }

    public load(): Observable<Article[]> {
        return super.load(null);
    }

    public get(): Observable<Article[]> {
        this.load();
        return super.get();
    }
}
